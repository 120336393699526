import React from 'react';

import styles from './ErrorAnimation.module.css';

export default function ErrorAnimation() {
  return (
    <div className={styles.icon}>
      <div className={styles.iconError}>
        <div className={styles.iconErrorX}>
          <div className={styles.iconErrorLeft} />
          <div className={styles.iconErrorRight} />
        </div>
        <div className={styles.iconErrorPlaceholder} />
        <div className={styles.iconErrorFix} />
      </div>
    </div>
  );
}
